.block {
	width: 100%;
	height: 14px;
}

.load-animate {
	animation: load 2s linear infinite;
	animation-fill-mode: forwards;
	background: #191919;
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#191919),
		color-stop(20%, #121212),
		color-stop(40%, #121212),
		to(#191919)
	);
	background-repeat: no-repeat;
	background-size: cover;
}
.load-animate.profile-pic {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
}

@-webkit-keyframes load {
	0% {
		background-position: -680px 0;
	}
	100% {
		background-position: 680px 0;
	}
}

@keyframes load {
	0% {
		background-position: -680px 0;
	}
	100% {
		background-position: 680px 0;
	}
}
@-webkit-keyframes load-image {
	0% {
		background-position: -680px 0;
	}
	100% {
		background-position: 680px 0;
	}
}
@keyframes load-image {
	0% {
		background-position: -680px 0;
	}
	100% {
		background-position: 680px 0;
	}
}
